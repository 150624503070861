
import { Component, Prop, Watch } from "vue-property-decorator";
import FormMixin from "@/mixins/Crud/FormMixin";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import SaveButton from "@/components/buttons/table_actions/SaveButton.vue";
import IContract from "@/interfaces/IContract";
import { mixins } from "vue-class-component";
import { CustomerContractStoreMixin } from "@/mixins/Stores/CustomerContractStoreMixin";
import {
  DEFAULT_BACKEND_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "@/config/Formats";

@Component({
  components: { SaveButton, DefaultForm },
  mixins: [FormMixin],
})
export default class CustomerContractForm extends mixins(
  CustomerContractStoreMixin
) {
  protected name = "CustomerContractForm.vue";

  @Prop({ required: false, default: "create" })
  public mode!: string;

  @Prop({ required: false, default: null })
  public data!: IContract;

  protected id?: number;

  protected title = "";
  protected text = "";
  protected starts_at!: Date;
  protected ends_at!: Date;
  protected createdAt?: Date | string;
  protected updatedAt?: Date | string;

  @Watch("data", { immediate: true, deep: true })
  protected onDataChanged(data: IContract) {
    if (data) {
      this.setFormValues(data);
    }
  }

  public getFormValues(): IContract | null {
    let contract: IContract = {
      title: this.title,
      text: this.text,
      starts_at: this.$moment(this.starts_at).format(
        DEFAULT_BACKEND_DATE_FORMAT
      ),
      ends_at: this.$moment(this.ends_at).format(DEFAULT_BACKEND_DATE_FORMAT),
    };
    if (this.data?.id && contract) {
      contract.id = this.data.id;
    }
    return contract;
  }

  protected setFormValues(data: IContract): void {
    if (data) {
      this.id = data.id;
      this.title = data.title;
      this.text = data.text;
      this.starts_at = new Date(data.starts_at);
      this.ends_at = new Date(data.ends_at);
      this.createdAt = data.createdAt;
      this.updatedAt = data.updatedAt;
    }
  }

  protected init() {}
}
