
import { Component, Vue } from "vue-property-decorator";
import CreateMixin from "@/mixins/Crud/CreateMixin";
import { CustomerContract } from "@/config/Modules";
import CustomerContractForm from "./CustomerContractForm.vue";
import { mixins } from "vue-class-component";
import { CustomerContractStoreMixin } from "@/mixins/Stores/CustomerContractStoreMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

@Component({
  components: { CustomerContractForm },
  mixins: [CreateMixin],
})
export default class CustomerContractCreate extends mixins(
  CustomerContractStoreMixin
) {
  protected name = "CustomerContractCreate";

  protected get resource(): string {
    return String(CustomerContract.resource).replace(
      "<customer_id>",
      this.$route?.params?.id
    );
  }

  protected get descriptionField(): string {
    return CustomerContract.description_field;
  }

  protected redirectAfterCreateSuccess(responseData: any): void {
    this.$router.replace({
      name: "CustomerContractUpdate",
      params: {
        id: responseData.contractible_id,
        contract_id: responseData.id,
      },
    });
  }

  public createItemAction(options: ICrudOptions) {
    return this.createCustomerContractItemAction(options);
  }
}
