var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column"},[_c('DefaultForm',{attrs:{"is-saving":_vm.getCustomerContractIsSaving,"mode":_vm.mode},on:{"submit":_vm.onSubmit,"abort":_vm.onAbort},scopedSlots:_vm._u([{key:"default",fn:function(formSettings){return [_c('div',[_c('b-field',{attrs:{"grouped":"","horizontal":formSettings.horizontal}},[_c('b-field',{attrs:{"horizontal":false,"label":String().capitalize(
                    _vm.$t('modules.customer.contract.starts_at')
                  ),"type":_vm.getCustomerContractValidationErrors &&
                  _vm.getCustomerContractValidationErrors.starts_at
                    ? 'is-danger'
                    : '',"message":_vm.getCustomerContractValidationErrors &&
                  _vm.getCustomerContractValidationErrors.starts_at
                    ? _vm.getCustomerContractValidationErrors.starts_at
                    : ''}},[_c('b-datepicker',{attrs:{"inline":false},model:{value:(_vm.starts_at),callback:function ($$v) {_vm.starts_at=$$v},expression:"starts_at"}})],1),_c('b-field',{attrs:{"horizontal":false,"label":String().capitalize(_vm.$t('modules.customer.contract.ends_at')),"type":_vm.getCustomerContractValidationErrors &&
                  _vm.getCustomerContractValidationErrors.ends_at
                    ? 'is-danger'
                    : '',"message":_vm.getCustomerContractValidationErrors &&
                  _vm.getCustomerContractValidationErrors.ends_at
                    ? _vm.getCustomerContractValidationErrors.ends_at
                    : '',"expanded":""}},[_c('b-datepicker',{attrs:{"inline":false},model:{value:(_vm.ends_at),callback:function ($$v) {_vm.ends_at=$$v},expression:"ends_at"}})],1)],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$tc('general.contracts', 1)),"type":_vm.getCustomerContractValidationErrors &&
                _vm.getCustomerContractValidationErrors.title
                  ? 'is-danger'
                  : '',"message":_vm.getCustomerContractValidationErrors &&
                _vm.getCustomerContractValidationErrors.title
                  ? _vm.getCustomerContractValidationErrors.title
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"","placeholder":String(_vm.$t('modules.customer.contract.placeholder.title')),"required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$tc('general.text', 1)),"type":_vm.getCustomerContractValidationErrors &&
                _vm.getCustomerContractValidationErrors.text
                  ? 'is-danger'
                  : '',"message":_vm.getCustomerContractValidationErrors &&
                _vm.getCustomerContractValidationErrors.text
                  ? _vm.getCustomerContractValidationErrors.text
                  : ''}},[_c('b-input',{attrs:{"type":"textarea","maxlength":"65000","icon":"","required":""},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1)],1)]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }