import { Component, Vue } from "vue-property-decorator";
import ICreateMixin from "@/mixins/interface/ICreateMixin";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

@Component({
  components: {},
})
export default class CreateMixin extends Vue implements ICreateMixin {
  protected name = "CreateMixin.ts";

  onSubmit(item: any): void {
    this.createItemAction({
      resource: this.resource,
      data: item,
      descriptionField: this.descriptionField,
    }).then((response: any) => {
      console.debug("createmixin onSubmit then", response.data);
      this.onSubmitSuccess(response);
      setTimeout(() => {
        if (this.$route.query.redirectTo) {
          this.$router.replace(String(this.$route.query.redirectTo));
          return;
        }

        this.redirectAfterCreateSuccess(
          response.data && response.data.data
            ? response.data.data
            : response.data
        );
      }, 1000);
    });
  }

  onSubmitSuccess(response: any): void {}

  onAbort(item: any): void {
    this.$router.back();
  }

  createItemAction(options: ICrudOptions): any {
    console.warn(
      "This is createItemAction from CreateMixin. Override this in your Create Component!"
    );
  }

  redirectAfterCreateSuccess(responseData: any) {}

  get descriptionField(): string {
    console.warn(
      "This is get descriptionField from CreateMixin. Override this in your Create Component!"
    );
    return "id";
  }

  get resource(): string {
    console.warn(
      "This is get resource from CreateMixin. Override this in your Create Component!"
    );
    return "";
  }
}
