
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DefaultForm extends Vue {
  protected name = "DefaultForm";

  @Prop({ required: false, default: "create" })
  public mode!: string;

  @Prop({ required: false, default: false })
  public isSaving!: boolean;

  @Prop({ required: false, default: true })
  public isHorizontal!: boolean;

  @Prop({ required: false, default: false })
  protected hideButtons!: boolean;

  protected get formSettings(): any {
    return {
      horizontal: this.isHorizontal,
    };
  }
}
