import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import IForm from "@/interfaces/IForm";

@Component
export default class FormMixin extends Vue implements IForm {
  protected isHorizontal = true;

  protected onSubmit(): void {
    this.$emit("submit", this.getFormValues());
  }

  protected onAbort(): void {
    this.$emit("abort", this.getFormValues());
  }

  public getFormValues(): any {
    console.error(
      "Insert getFormValues function to your child class of FormMixin"
    );
    return {};
  }

  public init(): void {
    console.error("Insert init() function to your child class of FormMixin");
  }

  public created(): void {
    this.init();
  }
}
