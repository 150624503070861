import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { CustomerContract } from "@/config/Modules";

const CustomerContractModule = namespace(CustomerContract.store);

@Component({})
export class CustomerContractStoreMixin extends Vue {
  /**
   * Actions
   */
  @CustomerContractModule.Action("create")
  protected createCustomerContractItemAction: any;

  @CustomerContractModule.Action("findOne")
  protected findOneCustomerContractItemAction: any;

  @CustomerContractModule.Action("update")
  protected updateCustomerContractItemAction: any;

  @CustomerContractModule.Action("findAll")
  protected findAllCustomerContractItemsAction: any;

  @CustomerContractModule.Action("resetSave")
  protected resetSaveCustomerContractAction: any;

  @CustomerContractModule.Action("resetList")
  protected resetListCustomerContractAction: any;

  @CustomerContractModule.Action("del")
  protected delCustomerContractItemAction: any;

  @CustomerContractModule.Action("findAllSubresource")
  protected findAllSubresourceCustomerContractItemsAction: any;

  @CustomerContractModule.Action("setDataList")
  protected setDataListCustomerContractAction: any;

  @CustomerContractModule.Action("upload")
  protected uploadCustomerContractAction: any;

  /**
   * Getters
   */
  @CustomerContractModule.Getter("getError")
  protected getCustomerContractError: any;

  @CustomerContractModule.Getter("getIsLoading")
  protected getCustomerContractIsLoading: any;

  @CustomerContractModule.Getter("getIsSaving")
  protected getCustomerContractIsSaving: any;

  @CustomerContractModule.Getter("getDataItem")
  protected getCustomerContractItem: any;

  @CustomerContractModule.Getter("getSuccess")
  protected getCustomerContractSuccess: any;

  @CustomerContractModule.Getter("getDataList")
  protected getCustomerContractList: any;

  @CustomerContractModule.Getter("getTotal")
  protected getCustomerContractTotal: any;

  @CustomerContractModule.Getter("getDeletedSuccess")
  protected getCustomerContractDeletedSuccess: any;

  @CustomerContractModule.Getter("getValidationErrors")
  protected getCustomerContractValidationErrors: any;

  /**
   * Mutations
   */
  @CustomerContractModule.Mutation("IS_LOADING")
  protected setCustomerContractLoading: any;

  @CustomerContractModule.Mutation("SET_IS_SAVING")
  protected setCustomerContractIsSaving: any;

  @CustomerContractModule.Mutation("SET_ERROR")
  protected setCustomerContractError: any;

  @CustomerContractModule.Mutation("SET_SUCCESS")
  protected setCustomerContractSuccess: any;

  @CustomerContractModule.Mutation("SET_DATA_ITEM")
  protected setCustomerContractItem: any;

  @CustomerContractModule.Mutation("SET_DATA_LIST")
  protected setCustomerContractList: any;

  @CustomerContractModule.Mutation("SET_TOTAL")
  protected setCustomerContractTotal: any;

  @CustomerContractModule.Mutation("SET_DELETED_SUCCESS")
  protected setCustomerContractDeletedSuccess: any;

  @CustomerContractModule.Mutation("SET_VALIDATION_ERRORS")
  protected setCustomerContractValidationErrors: any;

  // Additional...
}
