
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SaveButton extends Vue {
  protected name = "SaveButton";

  @Prop({ required: false, default: false })
  public loading!: boolean;

  @Prop({ required: false, default: "is-small" })
  public size!: string;
}
